import "./App.css";

import { publicURLS } from "./Routes";
//import { Routes, Route, useLocation } from "react-router-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

function App() {
  return (
    <>
      <Router>
        <Switch>
          {publicURLS.map(({ Component, path }, i) => (
            <Route key={i} exact path={path}>
              <div>
                <Component />
              </div>
            </Route>
          ))}
        </Switch>
      </Router>
    </>
  );
}

export default App;
